<template>
  <div>
    <p class="font-weight-regular">
      Gedragprofiel:
      <v-icon :color="this.convertColor(this.colorOne)">fas fa-circle</v-icon>
      <v-icon :color="this.convertColor(this.colorTwo)">fas fa-circle</v-icon>
      /
      <v-icon :color="this.convertColor(this.colorThree)">fas fa-circle</v-icon>
      <v-icon :color="this.convertColor(this.colorFour)">fas fa-circle</v-icon>
    </p>
  </div>
</template>

<script>

export default {
  name: "dashboardOwnScanGQSColorProfile",
  props: {
    colorOne: {
      type: String,
      required: true,
    },
    colorTwo: {
      type: String,
      required: true,
    },
    colorThree: {
      type: String,
      required: true,
    },
    colorFour: {
      type: String,
      required: true,
    },
  },
  methods: {
    convertColor(color) {
      switch (color) {
        case "red":
          return "#E90134";
        case "green":
          return "#019A41"
        case "blue":
          return "#56B2F5"
        case "yellow":
          return "#F6D43E"
      }
    },
  }
};
</script>
