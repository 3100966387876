<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Gedrag Quick Scan
        </h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" v-if="loading">
        <dashboard-own-scan-loading scan />
      </v-col>

      <v-col cols="12" v-else-if="rapportAvailable">
        <v-row>
          <v-col sm="12" md="6" offset-md="3">
            <pdf
                :src="this.imageUrl"
                height="50px"
            ></pdf>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-divider></v-divider>

            <dashboard-own-scan-g-q-s-color-profile :color-one="colorOne" :color-two="colorTwo" :color-three="colorThree" :color-four="colorFour" />

            <p class="font-weight-regular">
              Korte beschrijving:
              {{ description }}
            </p>
          </v-col>

          <v-col cols="12" class="text-center py-0">
            <v-btn color="primary" block @click="downloadRapport" :loading="downloadLoading" :disabled="downloadLoading">Download volledig profiel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <dashboard-own-scan-not-filled scan />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DashboardOwnScanNotFilled from "@/pages/dashboard/own/notfilled";
import pdf from 'vue-pdf'
import DashboardOwnScanGQSColorProfile from "@/pages/dashboard/own/GQSColorProfile";
import apiClient from "@/services/apiClient";
import {mapGetters} from "vuex";
import DashboardOwnScanLoading from "@/pages/dashboard/own/loading";
import axios from "axios";

export default {
  name: "dashboardOwnScanGQS",
  components: {
    DashboardOwnScanLoading,
    DashboardOwnScanGQSColorProfile,
    DashboardOwnScanNotFilled,
    pdf
  },
  data() {
    return {
      loading: true,
      rapportAvailable: false,
      imageUrl: "",
      downloadUrl: "",
      downloadLoading: false,
      colorOne: "green",
      colorTwo: "blue",
      colorThree: "red",
      colorFour: "yellow",
      description: "",
    }
  },
  computed: {
    ...mapGetters({
      token: "auth/accessToken",
      scan: "user/scan",
      user: "user/user"
    }),
  },
  mounted() {
    if (this.scan.gqs.access && this.scan.gqs.completed) {
      this.rapportAvailable = true;

      this.imageUrl = process.env.VUE_APP_API_URL + "scan/gqs/image?token=" + this.token;
      this.downloadUrl = process.env.VUE_APP_API_URL + "scan/gqs/download.pdf?token=" + this.token;

      apiClient.get("scan/gqs/data").then(response => {
        this.showSuccess = true;

        this.colorOne = response.data.colorOne;
        this.colorTwo = response.data.colorTwo;
        this.colorThree = response.data.colorThree;
        this.colorFour = response.data.colorFour;
        this.description = response.data.description;

        this.loading = false;
      }).catch(error => {
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.rapportAvailable = false;
    }
  },
  methods: {
    stopLoading() {
      this.downloadLoading = false;
    },
    downloadRapport() {
      this.downloadLoading = true;

      window.open(this.downloadUrl, "_blank");

      setTimeout(this.stopLoading, 2000);
    },
  }
};
</script>
